import React from 'react';
import JapaneseHeader from "../../components/header-ja";
import JapaneseFooter from "../../components/footer-ja";

import IconQuality from "../../assets/icon_service_quality.svg";
import IconTechnology from "../../assets/icon_service_technology.svg";
import IconCommitment from "../../assets/icon_service_commitment.svg";
import ServiceBanner from "../../assets/banner_services.svg";

import ServiceScanning from "../../assets/service_scanning.webp";
import ServiceWalkthrough from "../../assets/service_walkthrough.webp";
import ServiceTour from "../../assets/service_360.webp";

class Services extends React.PureComponent {
  render() {
    return (
      <>
        <JapaneseHeader />
        <div className="page-banner grey-bg">
          <div className="width-limit">
            <h1>サービス</h1>
            <p>
              一般的な XR ユースケース向けの開発サービスを競争力のある価格で提供します。
            </p>
            <img src={ServiceBanner} alt="" className="banner-image" />
          </div>
        </div>
        <div className="section">
          <h2>なんで私達なの？</h2>
          <div className="flex width-limit" id="service-us">
            <div className="service-us-item">
              <img src={IconQuality} alt="" className="service-us-icon" />
              <strong>品質</strong><br />
              顧客の大部分が日本に拠点を置いているため、私たちは期待される最高水準の仕事の品質を順守するよう努めています。
            </div>
            <div className="service-us-item">
              <img src={IconTechnology} alt="" className="service-us-icon" />
              <strong>テクノロジー</strong><br />
              私たちのチームは、最新の XR テクノロジーで豊富な経験を持つ業界の専門家で構成されています。
            </div>
            <div className="service-us-item">
              <img src={IconCommitment} alt="" className="service-us-icon" />
              <strong>献身</strong><br />
              私たちは顧客満足にこだわっています。 若いスタートアップとして、私たちは会社の成功にとって非常に重要であることを理解しています。
            </div>
          </div>
        </div>
        <div className="section grey-bg">
          <h2>私たちが提供するXRサービス</h2>
          <div className="width-limit">
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>3Dスキャン</h3>
                <p>
                  写真測量スキャン ソリューションを使用して手作りの製品をスキャンし、高品質の仮想アセットを作成します。 社内のカスタム ターンテーブル リグ、ソフトウェア スタック、開発パイプラインを構築して、生成されたアセットのスキャンと後処理を自動化しました。 これにより、最小限の時間でオブジェクトのすべての詳細をキャプチャできます。<br />
                  <b>&bull;</b> 正確な 3D 表現<br />
                  <b>&bull;</b> ウェブに最適化されたクロスプラットフォーム<br />
                  <b>&bull;</b> AR対応<br />
                  <b>&bull;</b> 多種多様な素材。</p>
              </div>
              <img className="service-page-item-img" src={ServiceScanning} alt="" />
            </div>
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>VR ウォークスルー</h3>
                <p>ブラウザから直接 URL だけでアクセスできる没入型の仮想世界を作成します。 仮想イベントや会議を主催し、究極のリモート コラボレーション エクスペリエンスを生み出します。リノベーションを行う前にリノベーションの様子を体験してみませんか？ 存在しない空間の仮想体験を簡単に作成することで、費用のかかるやり直しやエラーを排除します。</p>
              </div>
              <img className="service-page-item-img" src={ServiceWalkthrough} alt="" />
            </div>
            <div className="service-page-item">
              <div className="service-page-item-content">
                <h3>360 ビュー VR ツアー</h3>
                <p>既存のスペースの写真のようにリアルなデジタル ツインをすばやく生成します。 市販の 360 度カメラを利用して、当社の Web プラットフォームは、フロアプラン、マーカー、および注釈の作成をわずか数分で簡素化します。 当社のプラットフォームは、ユーザーの行動とインプレッションを定量化するための分析とヒートマップもサポートしています。</p>
              </div>
              <img className="service-page-item-img" src={ServiceTour} alt="" />
            </div>
          </div>
        </div>
        <JapaneseFooter />
      </>
    )
  }
}

export default Services;
